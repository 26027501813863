import React, { useContext } from "react";
import { Button, Grid, useMediaQuery, Container, Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import axios from "axios";
import "./uni-swap.css";
import Stack from "@mui/material/Stack";
import wolverinu from "../images/wolverinu.mp4";
import { useState } from "react";
import { useNftContract } from "../hooks";
import { toast } from "react-toastify";
import { defaultAccount } from "../utils/environment";
import homeBg from "../images/bgover.svg";

import { gasEstimationNFT } from "../utils";
import { AppContext } from "../context/context";

const Swap = () => {
  // const [opens, setopens] = useState(false);
  const [loading, setloading] = useState(false);
  const matches = useMediaQuery("(max-width:960px)");
  // const { account, active, activate, deactivate } = useWeb3React();
  const { account, connect, disconnect, signer } = useContext(AppContext);
  const contract = useNftContract(signer);
  const whitlistHandler = async () => {
    if (!account) {
      toast.error("Error! Please connect your wallet.");
    } else {
      try {
        setloading(true);
        let obj = {
          image: `https://ipfs.infura.io:5001/api/v0/cat?arg=QmSPoH3zTE8vHPt1SH7XzHNU6ENcM5BqWKWeqjitKndAn7`,
          description: "NFT WOLVERINU",
        };
        const ipfsobjdata = new FormData();
        ipfsobjdata.append("path", Buffer.from(JSON.stringify(obj)));
        const ipfsRes2 = await axios.post(
          "https://ipfs.infura.io:5001/api/v0/add",
          ipfsobjdata
        );
        let fn = contract.estimateGas;
        let id = ipfsRes2.data.Hash;
        const tx = await contract.mintNFT(id, {
          gasLimit: await gasEstimationNFT(account, fn, id),
        });
        await tx.wait();
        toast.success("Success! Transaction Confirmed.");
        setloading(false);
      } catch (err) {
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error(err?.message);
        }
        setloading(false);
      }
    }
  };

  const handleClickOpen = () => {
    // setopen(true);

    connect();
  };

  const handleLogoutopen = () => {
    // setlogoutopen(true);
    disconnect();
  };

  return (
    <>
      <Container maxWidth="md">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          padding="10px 50px"
        >
          <Grid item>
            <a
              href="https://www.wolverinu.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                textDecoration: "none",
                display: "flex",
              }}
            >
              <img src="logo.svg" width="50px" height="60px" alt="logo" />
              <Box
                style={{
                  display: "flex",
                  marginLeft: "5px",
                  WebkitBoxAlign: "center",
                  WebkitAlignItems: "center",
                  msFlexAlign: "center",
                  alignItems: "center",
                  color: "#C20F05",
                  fontSize: "20px",
                  fontWeight: 800,
                  letterSpacing: "1px",
                  textTransform: "capitalize",
                }}
              >
                Wolverinu
              </Box>
            </a>
          </Grid>

          <Grid item>
            {account ? (
              defaultAccount.toLowerCase() === account.toLowerCase() ? (
                <Link
                  to="/admin"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    sx={{
                      background:
                        "linear-gradient(72deg, #000, #900a0a 51%, #700000 95%)",
                      boxShadow: "0px 0px 13px rgba(249, 240, 250, 0.7)",
                      borderRadius: "8px",
                      color: "#fff",
                      padding: matches ? "10px 10px" : "7px 10px",
                      fontSize: matches ? "12px" : "15px",
                      border: "none",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Admin Page
                  </Button>
                </Link>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {!account ? (
              <Button
                sx={{
                  maxWidth: "100%",
                  minHeight: "45px",
                  background:
                    "linear-gradient(72deg, #000, #900a0a 51%, #700000 95%)",
                  boxShadow: "0px 0px 13px rgba(249, 240, 250, 0.7)",
                  borderRadius: "24px",
                  color: "#fff",
                  padding: matches ? "10px 10px" : "10px 10px",
                  fontSize: matches ? "12px" : "13px",
                  border: "none",

                  cursor: "pointer",
                }}
                onClick={handleClickOpen}
              >
                Connect to wallet
              </Button>
            ) : (
              <Button
                sx={{
                  maxWidth: "100%",
                  background: "linear-gradient(90deg,#890808, #5A0606)",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  color: "#ffff",
                  minHeight: "34px",
                  borderRadius: "8px",
                  fontSize: matches ? "12px" : "1rem",
                  fontWeight: matches ? "normal" : "bold",
                }}
                onClick={handleLogoutopen}
              >
                {account?.slice(0, 6) + "..." + account?.slice(-4)}
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>

      <Box
        py={16}
        px={16}
        bgcolor="black"
        style={{
          background: `url(${homeBg}),linear-gradient(72deg, rgb(0, 0, 0), rgb(144, 10, 10) 51%, rgb(112, 0, 0) 95%`,
          clipPath: "polygon(0 0, 100% 0%, 100% 85%, 0% 100%)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          // backgroundSize: "100% 100%",
        }}
      >
        <Container maxWidth="md">
          <Box
            fontSize={{ xs: "28px", md: "42px" }}
            fontWeight="700"
            color="#fff"
            zIndex="10"
          >
            WOLVERINU NFT
          </Box>
        </Container>
      </Box>
      <Grid
        container
        padding={{
          xs: "50px 10px",
        }}
        alignItems="center"
      >
        <Grid
          item
          margin="auto"
          color="white"
          xs={12}
          sm={9}
          md={7}
          border="2px solid red"
          borderRadius="12px"
          textAlign="center"
          padding="30px"
        >
          <Stack direction="column" rowGap={5} alignItems="center">
            <h1>Mint NFT</h1>
            <video
              style={{
                width: "50%",
                height: "50%",
              }}
              autoPlay
              muted
              loop
            >
              <source src={wolverinu} type="video/mp4"></source>
            </video>

            {!account ? (
              <Button
                sx={{
                  minWidth: "80%",
                  minHeight: "45px",
                  backgroundColor: "#970000",
                  color: "#fff",
                  padding: matches ? "10px 10px" : "10px 10px",
                  fontSize: matches ? "12px" : "13px",
                  border: "none",

                  cursor: "pointer",
                }}
                onClick={handleClickOpen}
              >
                Connect to wallet
              </Button>
            ) : (
              <Button
                disabled={loading}
                sx={{
                  minWidth: "80%",
                  minHeight: "45px",
                  backgroundColor: "#970000",
                  color: "#fff",
                  padding: matches ? "10px 10px" : "10px 10px",
                  fontSize: matches ? "12px" : "13px",
                  border: "none",

                  cursor: "pointer",
                }}
                onClick={whitlistHandler}
              >
                Mint NFT
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Swap;
