import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { contractAddress } from "../utils/environment";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { toast } from "react-toastify";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import RedditIcon from "@mui/icons-material/Reddit";
import footer from "../images/footer.svg";
// tokenAddress

export default function FooterMain() {
  return (
    <>
      <Box position="relative" mt={12} pt={0} overflow="hidden">
        <Box
          display="flex"
          justifyContent="center"
          borderBottom="1px solid #707070"
        >
          <img src={footer} height="400px" alt="" />
        </Box>
        <Container maxWidth="md">
          <Box
            textAlign="center"
            fontFamily="Proxima nova, sans-serif"
            fontSize={{ xs: "35px", sm: "48px" }}
            fontWeight={600}
            mt={10}
          >
            Join the community on
          </Box>

          <Grid container style={{ justifyContent: "center" }}>
            <Grid item xs={12} spacing={6} align="center">
              <Box component="h3" fontSize={{ xs: "20px", sm: "23px" }}></Box>

              <Box
                my={3}
                display="flex"
                justifyContent="space-evenly"
                width={{ xs: "100%", sm: "60%" }}
              >
                <a
                  href="https://t.me/wolverinu"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <TelegramIcon fontSize="large" style={{ color: "#FF1F13" }} />
                </a>
                <a
                  href="https://www.reddit.com/r/WOLVERINU/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <RedditIcon fontSize="large" style={{ color: "#FF1F13" }} />
                </a>

                <a
                  href="https://discord.com/invite/gxyWurnGpX"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <img
                    src="https://global-uploads.webflow.com/6191e507f3028a2aa81f9749/61dab039a9293e2f16605e59_Frame%20(16).svg"
                    alt=""
                    style={{ width: "27px", height: "30px" }}
                  />
                </a>
                <a
                  href="https://twitter.com/wolverinutoken"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <img
                    src="https://global-uploads.webflow.com/6191e507f3028a2aa81f9749/61dab03995e62b5d22ecbf7d_Twitter%20(1).svg"
                    alt=""
                    style={{ height: "30px" }}
                  />
                </a>

                <a
                  href="https://www.instagram.com/wolverinuofficial/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <InstagramIcon
                    fontSize="large"
                    style={{ color: "#FF1F13" }}
                  />
                </a>
              </Box>
              <Box
                component="h3"
                fontSize={{ xs: "24px", sm: "30px" }}
                font
                my={3}
                fontWeight={700}
                color="#c82222"
              >
                WOLVERINU, LLC
              </Box>
            </Grid>
            <Grid item xs={12} spacing={6}>
              <Box
                borderBottom="1px solid #707070"
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Box>
                  <Box display="flex" alignItems="center">
                    <Box
                      component="h3"
                      fontSize={{ xs: "20px", sm: "23px" }}
                      color="#c82222"
                    >
                      Company Number
                    </Box>
                    <Box
                      component="span"
                      fontSize={{ xs: "13px", sm: "16px" }}
                      fontWeight={700}
                      color="#fff"
                      ml={3}
                    >
                      L21000489411
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box
                      component="h3"
                      fontSize={{ xs: "20px", sm: "23px" }}
                      color="#c82222"
                    >
                      Head office adress
                    </Box>
                    <Box
                      component="span"
                      fontSize={{ xs: "13px", sm: "16px" }}
                      fontWeight={700}
                      color="#fff"
                      ml={2}
                    >
                      2598 E. SUNRISE BLVD, 2104, FORT LAUDERDALE, 33304
                    </Box>
                  </Box>
                </Box>
                <Box ml={5}>
                  <a href="/faqs" style={{ textDecoration: "none" }}>
                    <Box
                      component="h3"
                      fontSize={{ xs: "20px", sm: "23px" }}
                      color="#c82222"
                    >
                      FAQs
                    </Box>
                  </a>

                  <Box
                    component="h3"
                    fontSize={{ xs: "20px", sm: "23px" }}
                    color="#c82222"
                  >
                    Contract
                  </Box>
                  <Box display="flex" color="#fff">
                    <Box>
                      {contractAddress.slice(0, 5) +
                        "...." +
                        contractAddress.slice(-5)}
                    </Box>
                    <Box ml={2}>
                      <CopyToClipboard
                        text={contractAddress}
                        onCopy={() => toast("copied!")}
                      >
                        <FileCopyOutlinedIcon fontSize="14px" />
                      </CopyToClipboard>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box color="#fff" my={4}>
                This site contains material that may be copyrighted with use of
                which has not always been specifically authorized by the
                copyright owner. All materials contained in this site are
                presented as a transformative works in an act of parody, and the
                Wolverinu artist original interpretations. Wolverinu does not
                claim any partnership, endorsement, co-ownership, or sponsorship
                with any other entity. The existence and actions of Wolverinu do
                not adversely impact the market for any property owners.
                Furthermore, Wolverinu makes all content within available for
                the purpose of education in the ever growing Blockchain
                environment. These actions constitute for a “fair use” of any
                such material contained within under Title 17, Section 107 of
                the U.S. Copyright Law.
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                my={3}
              >
                <Box component="h3" fontSize={{ xs: "20px", sm: "23px" }}>
                  <img src="logo.svg" alt="" style={{ height: "66px" }} />
                </Box>
                <Box
                  fontSize={{ xs: "25px", sm: "30px" }}
                  fontWeight={400}
                  color="#c82222"
                  ml={1}
                >
                  Wolverinu
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
