import "./App.css";
import UniSwap from "./components/uni-swap";
import Admin from "./components/admin";
// import WalletConnect from "./modal";
import Modal from "./logoutModal";
import { useContext, useEffect, useState } from "react";
import NetworkChange from "./models/switchnetwork";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Web3 from "web3";
// import Footer from "./components/footer";
import FooterMain from "./components/footerMain";
import { defaultAccount } from "./utils/environment";
// import { useWeb3React } from "@web3-react/core";
import { AppContext } from "./context/context";

function App() {
  const [open, setopen] = useState(false);
  const [logoutopen, setlogoutopen] = useState(false);
  // const [switchNetwork, setswitchNetwork] = useState(false);
  // const { account, active } = useWeb3React();
  const { account, connect, disconnect } = useContext(AppContext);
  const web3 = new Web3(
    Web3.givenProvider
    //|| "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
  );

  // useEffect(() => {
  //   let chain = async () => {
  //     const chainid = await web3.eth.getChainId();
  //     if (chainid !== 1) {
  //       setswitchNetwork(true);
  //     } else {
  //       setswitchNetwork(false);
  //     }
  //   };
  //   chain();
  // }, []);

  return (
    <BrowserRouter>
      {/* <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} /> */}
      {/* <WalletConnect open={open} setopen={setopen} /> */}
      <Routes>
        <Route
          path="/"
          element={
            <UniSwap
              logoutopen={logoutopen}
              setlogoutopen={setlogoutopen}
              open={open}
              setopen={setopen}
            />
          }
        />
        <Route
          path="/admin"
          element={
            // <Navigate to="/" />
            account &&
            defaultAccount.toLowerCase() === account.toLowerCase() ? (
              <Admin
                logoutopen={logoutopen}
                setlogoutopen={setlogoutopen}
                open={open}
                setopen={setopen}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
      <FooterMain />
      {/* <Footer /> */}
      {/* <Modal logoutopen={logoutopen} setlogoutopen={setlogoutopen} /> */}
    </BrowserRouter>
  );
}
export default App;
