import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// Connectivity Imports

// import { Web3ReactProvider } from "@web3-react/core";
// import { getLibrary } from "./library";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContextProvider } from "./context/context";
ReactDOM.render(
  // <Web3ReactProvider getLibrary={getLibrary}>
  <AppContextProvider>
    <ToastContainer />
    <App />
  </AppContextProvider>,
  // </Web3ReactProvider>,
  document.getElementById("root")
);
