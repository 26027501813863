import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256 } from "@ethersproject/constants";
import { formatUnits, parseUnits } from "@ethersproject/units";
// add 10%
export function calculateGasMargin(value) {
  return +(
    (value * BigNumber.from(10000).add(BigNumber.from(1000))) /
    BigNumber.from(10000)
  ).toFixed(0);
}

export const gasEstimationNFT = async (active, fn, id) => {
  if (active) {
    const ap_estimateGas = await fn.mintNFT(id, MaxUint256).catch(() => {
      return fn.mintNFT(id);
    });
    return calculateGasMargin(ap_estimateGas);
  }
};

export const gasEstimationWhiteListUser = async (active, fn, data, bl) => {
  if (active) {
    const ap_estimateGas = await fn
      .WhiteListedUsers(data, bl, MaxUint256)
      .catch(() => {
        return fn.WhiteListedUsers(data, bl);
      });
    return calculateGasMargin(ap_estimateGas);
  }
};
