import React from "react";
import { Contract } from "@ethersproject/contracts";
// import { useWeb3React } from "@web3-react/core";
import { contractAddress } from "./utils/environment";

import contractAbi from "./utils/contractAbi.json";
// import Web3 from "web3";
// const web3 = new Web3(Web3.givenProvider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (address && ABI && signer) {
      return new Contract(address, ABI, signer);
    } else {
      return "error";
    }
  }, [address, ABI, signer]);
}

//--------------for Contract
// using WEB3(not signer)
// export function useSwapContract() {
//   const swapContract = new web3.eth.Contract(contractAbi, contractAddress);
//   return swapContract;
// }
//using WEBE-REACT(signer)
export function useNftContract(signer) {
  return useContract(contractAddress, contractAbi, signer);
}

//--------------for Token1
// using WEB3(not signer)
// export function useToken1Contract() {
//   const token1Contract = new web3.eth.Contract(token1Abi, token1Address);
//   return token1Contract;
// }
//using WEBE-REACT(signer)
// export function useToken1Contract1() {
//   return useContract(token1Address, token1Abi);
// }

//--------------for Token2
// using WEB3(not signer)
// export function useToken2Contract() {
//   const token2Contract = new web3.eth.Contract(token2Abi, token2Address);
//   return token2Contract;
// }
// //using WEBE-REACT(signer)
// export function useToken2Contract1() {
//   return useContract(token2Address, token2Abi);
// }
