import React, { CSSProperties, useContext } from "react";
import { Button, Grid, useMediaQuery, Container, Drawer } from "@mui/material";

import { Box } from "@mui/system";
import { useCSVReader } from "react-papaparse";
// import axios from "axios";
import "./uni-swap.css";

import { useState } from "react";
import { useNftContract } from "../hooks";
import { toast } from "react-toastify";
// import homeBg from "../images/bgover.svg";
import { gasEstimationWhiteListUser } from "../utils";
import { Link } from "react-router-dom";
import { AppContext } from "../context/context";
const Admin = ({ open, setopen, setlogoutopen, logoutopen, symbol }) => {
  const [opens, setopens] = useState(false);
  const [fileData, setfileData] = useState([]);
  const [loading, setloading] = useState(false);
  const matches = useMediaQuery("(max-width:960px)");
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const { account, signer } = useContext(AppContext);
  const contract = useNftContract(signer);
  const handleClickOpen = () => {
    setopen(true);
  };

  const handleLogoutopen = () => {
    setlogoutopen(true);
  };

  const handleOnDrop = (data) => {
    let arr = [];
    for (let index = 0; index < data.data.length - 1; index++) {
      let allAddress = data.data[index][0].trim();
      arr.push(allAddress);
    }
    setfileData(arr);
  };

  const handleOnRemoveFile = (data) => {
    setfileData([]);
  };

  const styles = {
    csvReader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
    },
    browseFile: {
      width: "50%",
    },
    acceptedFile: {
      border: "1px solid #ccc",
      height: 45,
      lineHeight: 2.5,
      paddingLeft: 10,
      width: "50%",
      color: "white",
    },
    remove: {
      borderRadius: 0,
      padding: "0 20px",
      width: "20%",
    },
    progressBarBackgroundColor: {
      backgroundColor: "red",
    },
  };

  const WhiteListAddress = async () => {
    try {
      if (account) {
        if (fileData.length <= 0) {
          toast.error("Please select a file");
          return;
        }
        setloading(true);
        const fn = contract.estimateGas;
        const tx = await contract.WhiteListedUsers(fileData, true, {
          gasLimit: await gasEstimationWhiteListUser(
            account,
            fn,
            fileData,
            true
          ),
        });
        await tx.wait();
        setloading(false);
      }
    } catch (err) {
      console.log(err);
      if (err?.data?.message) {
        toast.error(err?.data?.message);
      } else {
        toast.error(err?.message);
      }
      setloading(false);
    }
  };

  console.log(fileData, "fileData===>");

  return (
    <>
      <Box borderBottom="1px solid #707070">
        <Container maxWidth="md">
          <Grid
            // backgroundColor="#222222"
            container
            justifyContent="space-between"
            alignItems="center"
            padding="10px 50px"
          >
            <Grid item>
              <Link to="/">
                <img src="logo.svg" width="70px" height="auto" alt="logo" />
              </Link>
            </Grid>
            <Grid item>
              {!account ? (
                <Button
                  sx={{
                    maxWidth: "100%",
                    minHeight: "45px",
                    background: "linear-gradient(90deg,#890808, #5A0606)",
                    boxShadow: "0px 0px 13px rgba(182, 0, 211, 0.7)",
                    borderRadius: "24px",
                    color: "#fff",
                    padding: matches ? "10px 10px" : "10px 10px",
                    fontSize: matches ? "12px" : "13px",
                    border: "none",

                    cursor: "pointer",
                  }}
                  onClick={handleClickOpen}
                >
                  Connect to wallet
                </Button>
              ) : (
                <Button
                  sx={{
                    maxWidth: "100%",
                    background: "linear-gradient(90deg,#890808, #5A0606)",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    color: "#D50066",
                    minHeight: "34px",
                    borderRadius: "8px",
                  }}
                  onClick={handleLogoutopen}
                >
                  {account?.slice(0, 6) + "..." + account?.slice(-4)}
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="md">
        <Box
          component="h2"
          mt={8}
          sx={{
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#fff",
            textAlign: "center",
            fontFamily: "arial",
          }}
        >
          White List Address
        </Box>
        <Box
          // bgcolor="#190100"
          borderRadius="10px"
          position="relative"
          // top={{ xs: "80px", sm: "90px" }}
          // style={{
          //   backgroundBlendMode: "Pass through",
          //   backdropFilter: "blur(239px)",
          // }}
          // overflow="hidden"
          // className="details-box"
          p={10}
        >
          {/* {console.log(fileData, "fileData")} */}

          <Box
            component="h1"
            style={{
              color: "white",
            }}
          >
            Upload CSV
          </Box>
          <Box align="center">
            <CSVReader
              onUploadAccepted={
                (results) => handleOnDrop(results)
                //   (results) => {
                //   console.log("---------------------------");
                //   console.log(results);
                //   console.log("---------------------------");
                // }
              }
              onDragOver={(event) => {
                event.preventDefault();
                setZoneHover(true);
              }}
              onDragLeave={(event) => {
                event.preventDefault();
                setZoneHover(false);
              }}
            >
              {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
              }) => (
                <>
                  <div style={styles.csvReader}>
                    <button
                      type="button"
                      {...getRootProps()}
                      style={styles.browseFile}
                    >
                      Browse file
                    </button>
                    <div style={styles.acceptedFile}>
                      {acceptedFile
                        ? acceptedFile && acceptedFile.name
                        : "No file selected"}
                      {/* {acceptedFile && acceptedFile.name} */}
                    </div>
                    <button
                      {...getRemoveFileProps()}
                      onClick={() => handleOnRemoveFile()}
                      style={styles.remove}
                    >
                      Remove
                    </button>
                  </div>
                  <ProgressBar style={styles.progressBarBackgroundColor} />
                </>
              )}
            </CSVReader>
          </Box>
          <Box textAlign="center" mt={8}>
            <Button
              disabled={loading}
              onClick={WhiteListAddress}
              sx={{
                maxWidth: "100%",
                background: "linear-gradient(90deg,#890808, #5A0606)",
                boxShadow: "0px 0px 13px rgba(249, 240, 250, 0.7)",
                color: "#ffff",
                minHeight: "34px",
                borderRadius: "8px",
                fontSize: matches ? "12px" : "1rem",
                fontWeight: matches ? "normal" : "bold",
                cursor: "pointer",
              }}
            >
              {account ? "WHITELIST USER" : "connect to wallet"}
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Admin;
